﻿clr-options.clr-combobox-options[mblazor] {
    //overflow-y: auto !important;
}

.clr-combobox-options .option-group-label {
    margin-top: 0.25rem;
    font-weight: 500;
    padding: 0.15rem 0.6rem;
}

.clr-combobox-wrapper .clr-combobox-remove-btn:disabled,
.clr-combobox-wrapper .clr-combobox-remove-btn.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

.clr-combobox-trigger {
    padding: 0 var(--cds-global-space-2);
    right: var(--cds-global-space-2);
}

.clr-combobox-trigger.clr-combobox-single-clear {
    right: calc(var(--clr-combobox-trigger-gap) + var(--cds-global-space-3));
}

.clr-combobox-single-clear cds-icon {
    margin-bottom: var(--cds-global-space-1);
}

.clr-combobox-wrapper-clear {
    padding: 0 calc((var(--clr-combobox-trigger-gap)) + var(--cds-global-space-7) + var(--clr-combobox-trigger-padding-right)) 0 var(--clr-combobox-trigger-padding-left);
}

.clr-combobox-options .clr-combobox-options-loading {
    padding: calc(3*(1rem/var(--cds-global-base))) var(--cds-global-space-5)
}

.clr-combobox-wrapper .clr-combobox-input {
    vertical-align: bottom;
}