/**Tab Scroll**/
.tablist-wrapper {
    position: relative;

    ul {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .scrollable ul {
        margin: 0 30px;
        width: initial;
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
        scrollbar-width: none;
        /* Firefox */
    }

    &::-webkit-scrollbar {
        display: none;
        /* Safari and Chrome */
    }

    .scrollable > .tablist-left, .scrollable > .tablist-right {
        display: inline;
    }

    .tablist-left {
        display: none;
        position: absolute;
        z-index: 1;
        top: 30%;
        cursor: pointer;
        transform: rotate(-90deg);
    }

    .tablist-right {
        display: none;
        position: absolute;
        z-index: 1;
        top: 30%;
        right: 0;
        cursor: pointer;
        transform: rotate(90deg);
    }
}

clr-tabs {
    display: flex;
    flex-direction: column;
    min-height: 300px;
    overflow-y: auto;
    flex: 1 1 0%;
}

.tab-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 10px;
    padding-right: 15px;
    overflow-y: auto;

    & > h3:first-child {
        margin-top: 5px;
    }

    .grid-wrapper {
        max-height: 55vh;
    }
}