.main-container {
    height: 100dvh;
}

.content-wrapper {
    width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.content-area {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.content-footer {
    background: var(--cds-alias-object-interaction-background-active);
    color: var(--cds-alias-object-interaction-color);
    fill: var(--cds-alias-object-interaction-color);
    position: sticky;
    width: 100%;
    bottom: 0;
    display: flex;
    border-top-width: var(--clr-global-borderwidth);
    border-top-style: solid;
    border-top-color: var(--cds-alias-object-border-color);
    border-left-width: var(--clr-global-borderwidth);
    border-left-style: solid;
    border-left-color: var(--cds-alias-object-border-color);


    .statusbar-container {
        background: var(--cds-alias-object-app-background);
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;

        .statusbar {
            display: flex;
            flex-direction: column;
            flex: 1 1 auto;
            overflow-y: auto;

            .statusbar-header {
                background: var(--cds-alias-object-interaction-background-active);
            }

            .statusbar-content {
                max-width: 100vw;
            }
        }
    }
}

.content-area > *,
.tab-content > *,
.modal-body > * {
    flex-shrink: 0;
}
