.login-wrapper {
    background-image: initial;

    .login .login-group {
        form {
            padding: 0;
        }

        .btn {
            width: 100%;
            white-space: normal;
        }

        .login-button {
            padding: 0 0.6rem;
        }

        &[class*="clr-col-md"] {
            flex: none;
        }

        .error.active {
            word-wrap: anywhere;
        }
    }
}

.login-body {
    margin: 2rem 0;
}

@media screen and (max-width: 576px) {
    .login-wrapper .login {
        padding: var(--cds-global-space-9) 5%;
    }
}

@media screen and (max-width: 768px) {
    .login-wrapper .login {
        width: 100%;
        margin-left: 0;
        padding: var(--cds-global-space-9) 10%;
    }
}