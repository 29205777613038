﻿/**----Clarity dark enhancements---*/

[cds-theme~=dark] {
    .clr-select-wrapper select optgroup, .clr-multiselect-wrapper select optgroup {
        /* clr-forms-select-option-color */
        color: #17242b;
    }

    .modal-content {
        background-color: var(--clr-modal-bg-color);
    }

    .offcanvas {
        background-color: var(--clr-modal-bg-color);
    }

    .clr-input-group .clr-input-group-icon-action .icon {
        fill: var(--clr-forms-text-color) !important;
    }

    .gutter.gutter-horizontal {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: col-resize;
    }

    .gutter.gutter-vertical {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=');
        cursor: row-resize;
    }
    .toast-container {
        .toast {
            $toast-bg-color: #212529;
            $toast-border-color: #fff;
            --bs-toast-color: var(--cds-global-typography-color-400);
            --bs-toast-header-color: var(--cds-global-typography-color-400);
            --bs-toast-bg: #{rgba($toast-bg-color, .85)};
            --bs-toast-border-color: #{rgba($toast-border-color, .15)};
            --bs-toast-header-bg: #{rgba($toast-bg-color, .85)};
            --bs-toast-header-border-color: #{rgba($toast-border-color, .15)};

            .btn-close {
                --bs-btn-close-color: var(--cds-global-typography-color-400);
            }
        }
    }
}
