﻿/*Popover*/
[mblazor] [popover-host] {
    position: relative;
}

[mblazor] [popover] {
    position: absolute;
    z-index: 1;
}

[mblazor].popover {
    position: static;

    > .popover-overlay {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        min-height: 100vh;
        min-width: 100vw;
        max-height: 100vh;
        max-width: 100vw;
        background: transparent;
        z-index: 1;
    }

    .popover-trigger {
        width: max-content;
        height: max-content;
    }

    > .popover-body {
        position: fixed;
        top: 0;
        right: initial;
        left: 0;
        bottom: initial;
        z-index: 9999;
        opacity: 0;

        .popover-inverted {
            transform: scale(1, -1);

            > .popover-content > * {
                transform: scale(1, -1);
            }
        }
    }
}

.popover-body {
    color: inherit;
}
