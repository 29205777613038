﻿/*Datagrid.razor*/
.datagrid-wrapper[mblazor] {
    overflow: auto;
    margin-top: .6rem;
    position: relative
}

table[mblazor] {
    margin-top: 0;
    border: 0;
    border-radius: initial;

    .datagrid-select .clr-control-label {
        min-height: calc(var(--clr-forms-baseline)*4);
    }

    &.datagrid-compact tbody > tr > td {
        padding-top: 2px;
        padding-bottom: 3px;
        vertical-align: middle;
        line-height: 20px;
    }
    /*This allow floating filters to appear in correct place*/
    &:not(.fixed-header):not(.fixed-all) thead > tr > th {
        position: relative;
    }

    &.fixed-header thead > tr.header-primary > th,
    &.fixed-all thead > tr.header-primary > th {
        position: sticky;
        //top: 0;
        z-index: 1;

        &:has(.popover-body) {
            z-index: 2;
        }
    }

    &.fixed-header thead > tr.header-secondary > th,
    &.fixed-all thead > tr.header-secondary > th {
        position: sticky;
        top: 34px;
        z-index: 1;

        &:has(.popover-body) {
            z-index: 2;
        }
    }

    &.fixed-footer tfoot > tr > td,
    &.fixed-all tfoot > tr > td {
        position: sticky;
        bottom: 0;
        z-index: 1;

        &:has(.popover-body) {
            z-index: 2;
        }
    }
    /*Fixes issue mblazor/support#69 */
    &.fixed-header th:hover,
    &.fixed-all th:hover {
        z-index: 1;

        &:has(.popover-body) {
            z-index: 2;
        }
    }

    button.datagrid-column-title,
    button.datagrid-action-toggle {
        margin: 0;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: none;
        user-select: none;
    }

    button.datagrid-column-title {
        //color: var(--clr-table-font-color,#666);
    }

    button.datagrid-action-toggle {
        color: var(--clr-datagrid-icon-color,#8c8c8c);
    }

    button.datagrid-column-title:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    button.datagrid-column-title .sort-icon {
        //color: var(--clr-color-action-600,#0072a3);
        margin-left: 5px;
        height: .7rem;
        width: .7rem;
        vertical-align: middle;
    }

    th, td {
        text-align: left;
        vertical-align: middle;
    }

    .datagrid-placeholder {
        border-top: 0;
        padding: .6rem;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        justify-content: center;
        font-size: .8rem;
        font-size: var(--cds-global-typography-font-size-4,.8rem);
        color: #b2b3b3;
        color: var(--clr-datagrid-placeholder-color, #b2b3b3);
        width: 100%;
        padding: 0;
        margin-bottom: 0px;
        border-bottom-width: 0px;

        .datagrid-placeholder-image {
            height: 3rem;
            width: 3rem;
            margin-bottom: .6rem;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 50%;
            background-image: url('images/noresults.svg')
        }
    }

    tfoot tr td {
        padding: 0;
    }

    clr-dg-footer .datagrid-footer-description {
        /*Bug fix: match height of paginator for centering*/
        /*line-height: 1.8rem;*/
    }

    .row-unselectable, .row-selectable {
        &:hover, &.row-hover {
            background-color: var(--clr-datagrid-row-hover-color);

            & td {
                background-color: var(--clr-datagrid-row-hover-color);
            }
        }

        &.row-selected, &.row-selected + &.row-detail {
            color: var(--clr-datagrid-row-selected);
            background-color: var(--clr-datagrid-row-selected-background-color);

            & td {
                color: var(--clr-datagrid-row-selected);
                background-color: var(--clr-datagrid-row-selected-background-color);
            }

            &:hover {
                background-color: var(--clr-datagrid-row-selected-hover-background-color);

                & td {
                    background-color: var(--clr-datagrid-row-selected-hover-background-color);
                }
            }
        }
    }
    /**Column resizer*/
    .col-resizable {
        position: relative;
    }

    .col-resizer {
        top: 0;
        right: 0;
        width: 5px;
        position: absolute;
        cursor: col-resize;
        user-select: none;
        height: calc(100% - 8px);
        margin: 4px 0;
        padding: 0 4px;
        z-index: 1;

        &::after {
            content: '';
            display: block;
            border-right: 1px solid #ccc;
            height: 100%;
        }

        &:hover::after {
            border-right: 2px solid var(--clr-btn-default-hover-color, #00567a);
        }

        &:active::after {
            border-right: 1px dotted var(--clr-border-right-color,#79c6e6);
        }
    }
    /**Row selector and expand column styles*/
    .row-selector-col,
    .row-expand-col,
    .row-action-col {
        min-width: 30px;
        max-width: 30px;
        width: 30px;
        position: relative;
        padding: 0;
    }

    .row-selector-col {
        padding: 0 0 0 7px;
    }

    .row-action-col {
        padding-left: 7px;
    }

    .row-expand-col > .datagrid-expandable-caret {
        padding: 0;
        text-align: center;
    }

    .row-selector-col::after,
    .row-action-col::after,
    .row-expand-col::after {
        content: "";
        width: .05rem;
        height: calc(100% - .5rem);
        position: absolute;
        right: 0;
        top: .25rem;
        background-color: #ccc;
        background-color: var(--clr-table-border-color,#ccc);
    }

    .row-selector-col > .datagrid-select .clr-control-label {
        margin-top: 0;
    }

    tr.row-detail td {
        border-top: 0;
        padding-top: 3px;
    }

    tr.row-expanded .row-selector-col::after,
    tr.row-expanded .row-expand-col::after,
    tr.row-expanded .row-action-col::after {
        height: calc(100% - .25rem);
    }

    tr.row-expanded + tr.row-detail td.row-expand-col::after,
    tr.row-expanded + tr.row-detail td.row-selector-col::after,
    tr.row-expanded + tr.row-detail td.row-action-col::after {
        height: 100%;
        top: 0;
    }

    .datagrid-expandable-angle .datagrid-expandable-angle-button {
        //width: 1.30rem;
    }
    /*Grouping*/
    tbody.group-header .group-label {
        outline: none;
        padding: .3rem .325rem;
        background-color: var(--clr-stack-view-expandable-row-bg-color);
        transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
        color: var(--clr-stack-view-expandable-row-color);
        cursor: pointer;
    }

    tbody.group-values {
    }

    tbody.group-header.group-expanded .group-label {
        color: var(--clr-stack-view-stack-block-expanded-text-color);
        background-color: var(--clr-stack-view-stack-block-expanded-bg-color);
    }

    tbody.group-header:hover .group-label {
        color: var(--clr-stack-view-stack-block-expandable-hover);
        background-color: var(--clr-stack-view-expandable-row-bg-hover);
        cursor: pointer;
    }

    tbody.group-header .group-label cds-icon {
        margin-right: 7px;
    }

    th {
        font-size: var(--cds-global-typography-font-size-2)
    }

    /*
    & > :not(:last-child) > :last-child > * {
        border-bottom-color: var(--clr-datagrid-default-border-color);
        border-bottom-width: var(--clr-table-borderwidth);
    }
    */

    .datagrid-column {
        display: table-cell;
    }
}
/*DatagridColumnFilter.razor*/
clr-dg-filter[mblazor] {
    input {
        padding-right: 20px;
    }

    button.datagrid-filter-toggle {
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        background: none;
        cursor: pointer;
        float: right;
        vertical-align: middle;
        height: .7rem;
        width: .7rem;
        margin: 0 0 0 .3rem;
        background-repeat: no-repeat;
        background-size: contain;
        color: #b2b3b3;
        color: var(--clr-color-neutral-500,#b2b3b3);
    }

    .datagrid-filter-close-wrapper {
        position: absolute;
        top: 3px;
        right: 2px;
    }
}

clr-dg-filter[mblazor] .datagrid-filter-toggle.datagrid-filter-open,
clr-dg-filter[mblazor] .datagrid-filter-toggle.datagrid-filtered,
clr-dg-filter[mblazor] .datagrid-filter-toggle:hover,
.datagrid-inline-filter[mblazor] .clear-btn:hover {
    color: #0072a3;
    color: var(--clr-color-action-600,#0072a3);
}

clr-dg-filter[mblazor] .clr-validate-icon,
.datagrid-inline-filter[mblazor] .clr-validate-icon,
.datagrid-inline-filter[mblazor] .clear-btn {
    margin-left: -1.2rem;
}

clr-dg-filter[mblazor] .datagrid-filter-container,
.datagrid-inline-filter[mblazor] {
    display: flex;
    flex-direction: row;
    font-weight: normal;
    align-items: center;
}

.datagrid-inline-filter[mblazor] input,
.datagrid-inline-filter[mblazor] select {
    font-size: 11px !important;
}

th.first-col clr-dg-filter[mblazor] .datagrid-filter {
    left: 2px;
    right: initial;
    top: 100%;
}

.datagrid-inline-filter[mblazor] input {
    flex: 1 0 auto;
    padding-right: 1.23rem;
    width: 100%;
}

.datagrid-inline-filter[mblazor] .clear-btn {
    flex: 0 1 auto;
    cursor: pointer;
    width: 13px;
}

clr-dg-footer.datagrid-footer {

    .pagination-size .clr-page-size-select {
        letter-spacing: initial;
    }

    .column-switch-wrapper .column-switch {
        padding: 5px 10px;
    }

    .column-switch-wrapper .clr-form-control-disabled {
        height: initial;
    }

    .column-switch ul.switch-content {
        padding-right: var(--cds-global-space-6);
        margin-bottom: var(--cds-global-space-6);
    }
}

.column-switch-wrapper .column-switch form.clr-form {
    padding: 0;

    .clr-form-control {
        margin: 0;
    }
}

:root [cds-theme] {
    --clr-table-data-cell-font-size: var(--cds-global-typography-font-size-2);
}

.table td.datagrid-placeholder-wrapper {
    padding: 0;
    border-top-width: 0;
}

.datagrid {
    margin-top: 0;
    overflow: initial;
}

.table td.datagrid-placeholder-wrapper .datagrid-empty {
    padding: 20px;
}

.table tr:last-child td {
    border-bottom-style: solid;
    border-bottom-color: var(--clr-tablerow-bordercolor);
}

.table tbody:last-child tr:last-child td:first-child,
.table tbody:last-child tr:last-child td:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.datagrid-table-wrapper {
    min-height: initial;
}

.column-switch .switch-header{
    justify-content: end;
}

tr.header-primary > th:last-child .col-resizer {
    display: none;
}
