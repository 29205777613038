﻿/*TimeSelector*/
.timepicker {
    width:auto;
    height:auto;

    .calendar-btn.switcher:disabled {
        color: inherit;
        opacity: .4;
        pointer-events: none;
    }

    .calendar-btn.switcher.ampm {
        font-size: .6rem;
        font-size: var(--clr-day-font-size, 0.6rem);
        font-weight: 600;

        .is-selected {
            color: #666;
            color: var(--clr-p1-color, #666666);
            pointer-events: none;
        }
    }

    td {
        text-align: center;
        user-select: none;
    }

    span {
        font-size: .9rem;
        font-size: var(--clr-calendar-picker-btn-font-size, 0.9rem);
    }

    input {
        font-size: .9rem;
        font-size: var(--clr-calendar-picker-btn-font-size,.9rem);
        text-align: center;
        border-bottom: 0;
    }
}

/*Fixing bug where validation icon overlaps error text*/
clr-time-container[mblazor] clr-control-error.clr-subtext {
    margin-left: 20px;
}

/*RangeCalendar*/
.daterangepicker .day-btn.is-selected {
    background-color: #0072a3;
    background-color: var(--clr-btn-primary-bg-color, #0072a3);
    /* clr-btn-primary-color */
    color: white;
    color: var(--clr-btn-primary-color, white);
}

.daterangepicker .day-btn {
    padding: .05rem 0;
    line-height: 1.6rem;
    
    .day-btn > div {
        border-top: 1px dashed transparent;
        border-bottom: 1px dashed transparent;
    }

    .is-in-range > div {
        /* clr-calendar-active-cell-background-color */
        /* 
        background: #324f62;
        background: var(--clr-calendar-active-cell-background-color, #324f62);
        */

        background-color: #d8e3e9;
        background-color: var(--clr-global-selection-color,#324f62);

        /* 
        color: black;
        color: var(--clr-dropdown-active-text-color, black)
        */
    }

    .is-highlight > div {
        border-color: #0072a3;
        border-color: var(--clr-btn-primary-bg-color, #0072a3);
    }
}

/*DateTimePicker*/
.datetimepicker {
    width: auto;
    height: auto;

    > tr > td:last-of-type {
        vertical-align: top;
    }

    .datepicker {
        margin-top: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        border: 0;
        border-right: 0.05rem solid var(--clr-calendar-border-color, #cccccc);
    }

    .timepicker {
        margin-top: 0;
        border: 0;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    section.btn-area {
        margin-left: 10px;
        margin-top: 5.1rem;

        > button {
            margin-right: 0;
        }
    }
}
/*Picker custom icons*/
.clr-input-group .clr-input-group-icon-action .icon {
    height: .9rem;
    width: .9rem;
    -webkit-transform: translate(-.05rem,-.05rem);
    transform: translate(-.05rem,-.05rem);
}

/*
.icon {
    display: inline-block;
    margin: 0;
    height: 16px;
    width: 16px;
    vertical-align: middle;
    fill: currentColor;
}
*/

.clr-datetime-input {
    padding: 0 var(--cds-global-space-10) 0 0;
}

.clr-datetime-input .clr-input-group-icon-action {
    margin: auto;
    position: absolute;
    bottom: var(--cds-global-space-1);
}
