.gutter {
    background-color: var(--cds-alias-object-border-color);
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAJ0lEQVQoU2M0Njb+zwAEZ8+eZYSx4QwUQZAqdMCIVXDUzNHwxJuWAKJtWZ/UptGBAAAAAElFTkSuQmCC');
    cursor: col-resize;
}

.gutter.gutter-vertical {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFCAYAAABSIVz6AAAAKElEQVQoU2M0Njb+zwAEZ8+eZQTR9OIz0ssidI+BfTkQYOB8PFBBDQDaEHZmO9I16wAAAABJRU5ErkJggg==');
    cursor: row-resize;
    margin-top: 5px;
}