﻿/*NumberInput.razor*/
.number-input-button {
    line-height: 0.6rem !important;

    cds-icon {
        //height: 0.45rem !important;
        min-height: var(--cds-global-space-6) !important;
        min-width: var(--cds-global-space-6) !important;
        height: var(--cds-global-space-6) !important;
        width: var(--cds-global-space-6) !important;
    }

    &.number-input-button-bottom {
        top: 0.45rem;
        bottom: 0;
    }
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

/**FileInput.razor*/
.file-input[mblazor]{
    input[type=file] {
        opacity: 0;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        margin: 5px;
    }

    label {
        color: var(--clr-color-action-600, #0079ad);
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;
        cursor: pointer;
        width: fit-content;
        height: 25px;
        border: 0.05rem solid currentColor;
        border-radius: 0.2rem;
        padding: 0.3rem 0.6rem;

        span.file-btn-text {
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
            padding-left: 5px;
            min-width: 60px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

/*PasswordInput.razor*/
input::-ms-reveal,
input::-ms-clear {
    display: none;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

/* Disable the input action */
.clr-input-group-icon-action:disabled {
    color: #b3b3b3;
    color: var(--clr-forms-text-disabled-color, #b3b3b3);
    cursor: not-allowed;
}

.clr-input-group .clr-input-group-icon-action cds-icon[direction="down"] {
    transform: translate(calc(-1*var(--cds-global-space-1)),calc(-1*var(--cds-global-space-1))) rotate(180deg);
}

.clr-input-group-icon-action.clr-input-group-single-clear {
    right: calc(var(--cds-global-space-9));
}

.clr-range-wrapper {
    margin-bottom: calc(var(--clr-forms-range-thumb-height)/2);
}

.number-input-visual {
    flex-direction: row;
    width: 100%;

    input {
        height: var(--clr-btn-appearance-form-height);
        max-height: initial;
        border-top: var(--cds-alias-object-border-width-100) solid var(--clr-forms-border-color);
        text-align: center;
        width: 100%;
        min-width: initial !important;
    }

    &.clr-focus {
        width: 100%;
    }

    &-fullwidth {
        input {
            width: 100% !important;
        }
    }
}

.clr-error .clr-subtext {
    color: var(--clr-forms-invalid-text-color, hsl(9, 100%, 38%));
}

.clr-numberfilter {
    select {
        font-weight: var( --clr-font-weight-extrabold);
        text-align: center;

        &:focus {
            outline: none !important;
            box-shadow: none !important;
        }

        option {
            background: var(--cds-alias-object-container-background,var(--cds-global-color-white,#fff));
            color: var(--cds-global-typography-color-400,var(--cds-global-color-construction-900,#21333b));
            padding: var(--cds-global-space-4,calc(6 * 1rem / var(--cds-global-base,20)));
            font-size: var(--cds-global-typography-font-size-3,calc(13 * 1rem / var(--cds-global-base,20)));
            font-weight: var( --clr-font-weight-extrabold);
            text-align: left;

            &:checked {
                background: var(--cds-alias-object-interaction-background-selected,var(--cds-global-color-blue-75,#e0f6ff));
            }

            &:hover {
                background: var(--cds-alias-object-interaction-background-hover,var(--cds-global-color-construction-50,#f1f6f8));
            }
        }
    }
}

textarea.clr-textarea {
    padding: var(--cds-global-space-3);
}
