/* offcanvas */
.offcanvas {
    --bs-offcanvas-zindex: 1055;
}

.offcanvas-footer {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
}

.offcanvas-start.offcanvas-size-sm,
.offcanvas-end.offcanvas-size-sm {
    width: 25vw;
}

.offcanvas-start.offcanvas-size-md,
.offcanvas-end.offcanvas-size-md {
    width: 40vw;
}

.offcanvas-start.offcanvas-size-lg,
.offcanvas-end.offcanvas-size-lg {
    width: 55vw;
}

.offcanvas-start.offcanvas-size-xl,
.offcanvas-end.offcanvas-size-xl {
    width: 75vw;
}

.offcanvas-top.offcanvas-size-sm,
.offcanvas-bottom.offcanvas-size-sm {
    height: 25vh;
}

.offcanvas-top.offcanvas-size-md,
.offcanvas-bottom.offcanvas-size-md {
    height: 40vh;
}

.offcanvas-top.offcanvas-size-lg,
.offcanvas-bottom.offcanvas-size-lg {
    height: 55vh;
}

.offcanvas-top.offcanvas-size-xl,
.offcanvas-bottom.offcanvas-size-xl {
    height: 75vh;
}

