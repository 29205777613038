﻿.dropdown[mblazor] .dropdown-toggle > button:not(.btn) {
    background: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    color: var(--clr-dropdown-active-text-color,#000)
}

.popover-body .dropdown-menu {
    position: relative !important;
}

