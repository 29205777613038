﻿/**----Clarity enhancements---*/

/*Bug fix: Disable style for radio buttons, same as checkboxes*/
.clr-form-control-disabled .clr-radio-wrapper label::before {
    background-color: #ccc;
    background-color: var(--clr-forms-checkbox-disabled-background-color, #cccccc)
}

/*Bug fix: Focus with 100% width for input-group */
.clr-input-group.clr-focus {
    width: auto;
}

/*Bug fix: input group icon should be inside the input not make it wider */
.clr-input-group {
    position: relative;
}

.clr-input-group-icon-action {
    position: absolute;
    right: 0;
}

.clr-input-with-action {
    max-width: calc(100% - 1.8rem) !important;
}

/*Bug fix: date picker inside datagrid */
.datepicker td {
    background-color: transparent !important;
}

/* Bug fix: dropdown angle not aligning in header*/
header .header-actions .dropdown:last-of-type .dropdown-toggle {
    padding-right: 1rem !important;
}
.dropdown .dropdown-toggle cds-icon[shape^=angle],
.dropdown .dropdown-toggle cds-icon[shape^=angle] {
    right: 0.25rem !important;
    zoom: 0.85;
}
.dropdown .dropdown-toggle.nav-text cds-icon[shape^=angle],
.dropdown .dropdown-toggle.nav-text cds-icon[shape^=angle] {
    position: initial !important;
    width: 1rem !important;
    height: 1rem !important;
}