﻿/*Modal.razor*/
clr-modal[mblazor] {
    display: none;
    &.open {
        display: inline;
    }
}

.modal-body {
    display: flex;
    flex-direction: column;
}

.modal-footer {
    border: none;
    margin-top: 0;
}

.modal.modal-nooverlay{
    top: initial;
    left: initial;
    padding: calc(var(--cds-global-space-9));
    padding-top: 0;
    padding-left: 0;
}
