﻿@use 'sass:map';
@use 'sass:meta';
@use '@clr/ui/src/utils/mixins';
@use '@clr/ui/src/utils/variables/variables';
@use '@clr/ui/src/layout/main-container/variables.header' as header-variables;
@use '@clr/ui/src/layout/vertical-nav/vertical-nav.clarity';
@use '@clr/ui/src/layout/nav/header.clarity';
@use '@clr/ui/src/layout/nav/variables.responsive-nav' as responsive-nav-variables;
@use '@clr/ui/src/utils/variables/variables' as util-variables;
@use '@clr/ui/src/layout/vertical-nav/variables.vertical-nav' as vertical-nav-variables;
@use '@clr/ui/src/layout/tabs/tabs.clarity' as tabs;
@use '@clr/ui/src/layout/nav/variables.subnav' as nav-variables;
@use '@cds/core/tokens/tokens.scss';
@use '@clr/ui/src/layout/nav/_responsive-nav.clarity' as res;


/*Custom settings for divider in sidenav*/
.sidenav .divider {
    border-bottom: .05rem solid #ccc;
    display: block;
    margin: 3px 3px 4px 6px;
    margin-right: 3px;
    margin-left: 6px;
}

/*Fix vertical nav top padding*/
.clr-vertical-nav.clr-nav-level-1,
.clr-vertical-nav.clr-nav-level-2 {
    padding-top: 0 !important;
}

.clr-vertical-nav .nav-icon {
    margin-bottom: var(--cds-global-space-2);
}

header {
    z-index: 999;
}

header .settings {
    .nav-link {
        color: var(--clr-header-font-color, #fafafa);
        opacity: var(--clr-header-nav-opacity, .65);
    }

    .settings .nav-link:hover::before {
        opacity: var(--clr-header-nav-opacity, .65);
    }

    .settings .nav-link:hover {
        opacity: 1;
    }
}


/*Use MBlazor Dropdown in header*/
header .settings > .dropdown .dropdown-toggle,
header .header-actions > .dropdown .dropdown-toggle,
.header .settings > .dropdown .dropdown-toggle,
.header .header-actions > .dropdown .dropdown-toggle {
    position: relative;
    line-height: 3rem;
    height: 3rem;
    outline-offset: -0.25rem;
    /* clr-header-font-color */
    color: #fafafa;
    color: var(--clr-header-font-color, #fafafa);
    /* clr-header-nav-opacity */
    opacity: 0.65;
    opacity: var(--clr-header-nav-opacity, 0.65);

    &:enabled:hover,
    &:enabled:hover,
    &:enabled:hover,
    &:enabled:hover {
        /* clr-header-nav-hover-opacity */
        opacity: 1;
        opacity: var(--clr-header-nav-hover-opacity, 1);
    }

    &:disabled,
    &:disabled,
    &:disabled,
    &:disabled {
        cursor: not-allowed;
    }
}

.nav .nav-item:last-child {
    margin-right: 0;
}

.nav-content > .nav-link {
    font-weight: var(--clr-vertical-nav-item-top-level-font-weight);
}

.nav-trigger {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.clr-vertical-nav .nav-trigger {
    padding: 0;
    height: initial;
}

.clr-vertical-nav .nav-trigger-collapse,
.clr-vertical-nav .nav-trigger-info {
    flex-grow: 1;
    border: 0;
    background-color: transparent;
    display: flex;
    padding: var(--cds-global-space-5) var(--cds-global-space-7);
}

.clr-vertical-nav .nav-trigger-info {
    justify-content: start;
    flex-grow: 0;
    order: 1;

    cds-icon {
        color: var(--clr-vertical-nav-toggle-icon-color);

        &:hover {
            color: var(--clr-vertical-nav-toggle-icon-hover-color);
        }
    }
}

.clr-vertical-nav .nav-trigger-collapse{
    justify-content: flex-end;
    order: 2;
}


@media screen and (max-width: 992px) {
    .main-container.open-hamburger-menu .clr-vertical-nav .nav-trigger, .main-container.open-overflow-menu .clr-vertical-nav .nav-trigger {
        display: flex;
    }

    .clr-vertical-nav .nav-trigger-collapse {
        display: none;
    }

    .clr-vertical-nav .nav-trigger-info {
        flex-grow: 1;
    }
}

.main-container-fullscreen {
    .header-hamburger-trigger,
    .header-overflow-trigger {
        display: inline-block;
        border: none;
        background: none;
        cursor: pointer;
        font-size: tokens.$cds-global-space-9;
        @include mixins.equilateral(header-variables.$clr-header-height);
        padding: 0 0 tokens.$cds-global-space-3 0;
        //4px to vertically align the trigger lines. display flex was not working on all browsers.
        text-align: center;
        white-space: nowrap;

        &:focus {
            // So that the outline styles aren't hidden because of the browser
            outline-offset: calc(-1 * tokens.$cds-global-space-4);
        }

        @include header.header-nav-appearance();
    }

    .header-nav.clr-nav-level-1,
    .subnav.clr-nav-level-1,
    .sub-nav.clr-nav-level-1,
    .clr-vertical-nav.clr-nav-level-1 {
        @include res.sliding-nav-positioning();
        transform: translateX(calc(-1 * #{responsive-nav-variables.$clr-sliding-panel-width}));
        transition: responsive-nav-variables.$clr-sliding-panel-animation;
    }

    .header-nav.clr-nav-level-2,
    .subnav.clr-nav-level-2,
    .sub-nav.clr-nav-level-2,
    .clr-vertical-nav.clr-nav-level-2 {
        @include res.sliding-nav-positioning(0, 0, 0, auto);
        transform: translateX(responsive-nav-variables.$clr-sliding-panel-width);
        transition: responsive-nav-variables.$clr-sliding-panel-animation;
    }

    .subnav.clr-nav-level-1,
    .sub-nav.clr-nav-level-1,
    .subnav.clr-nav-level-2,
    .sub-nav.clr-nav-level-2 {
        .nav,
        aside {
            flex-direction: column;
            align-items: stretch;
        }

        aside {
            justify-content: center;
            width: 100%;
        }
        //Such deep nesting is unfortunately required here because we don't want
        //navs which are not subnav to behave in a similar way.
        // TODO: more specificity in classname is preferable to nesting...
        .nav {
            padding-left: 0;
            gap: tokens.$cds-global-space-5;

            .nav-item {
                height: tokens.$cds-global-space-11;
                margin-right: 0;
            }

            .nav-link {
                padding: vertical-nav-variables.$clr-vertical-nav-item-padding;
                width: 100%;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;

                @include vertical-nav.vertical-nav-link-colors();

                &:hover,
                &.active {
                    box-shadow: none;
                }

                &:hover {
                    &:not(.active)::before {
                        content: none;
                    }
                }
            }
        }
    }

    .clr-vertical-nav.clr-nav-level-1,
    .clr-vertical-nav.clr-nav-level-2 {
        border-right: none;
    }
}

.main-container {
    .header-overflow-trigger {
        position: relative;

        &::after {
            position: absolute;
            content: '';
            @include header.header-section-divider();
            left: 0;
        }
    }

    .header {
        .branding {
            //max-width: mixins.baselinePx(240);
            max-width: initial !important;
            min-width: 0;
            overflow: hidden;
        }

        .header-hamburger-trigger + .branding {
            padding-left: 0;
            //margin-left: calc(-1 * tokens.$cds-global-space-5);
            //TODO: deprecate .clr-icon, .logo
            .clr-icon,
            .logo,
            cds-icon,
            clr-icon {
                display: none;
            }
        }

        .branding + .header-overflow-trigger,
        .header-nav + .header-overflow-trigger,
        .header-nav + .cdk-visually-hidden + .header-overflow-trigger {
            margin-left: auto;
        }
    }
}

.main-container {
    &.open-hamburger-menu,
    &.open-overflow-menu {
        .header {
            .header-backdrop {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: tokens.$cds-alias-object-overlay-backdrop-background;
                cursor: pointer;
                z-index: responsive-nav-variables.$clr-sliding-panel-backdrop-z-index;
            }

            .header-nav.clr-nav-level-1,
            .header-nav.clr-nav-level-2 {
                .nav-link {
                    flex: 0 0 auto;

                    .nav-icon,
                    .fa {
                        display: none;
                    }

                    .nav-text {
                        display: inline-block;
                        padding: tokens.$cds-global-space-4 0 tokens.$cds-global-space-4 tokens.$cds-global-space-9;
                        white-space: normal;
                        // color, line-height and font-weight should be removed or refactored in separate JIRA
                        color: responsive-nav-variables.$clr-sliding-panel-text-color;
                        line-height: tokens.$cds-global-space-9;
                        font-weight: normal;
                    }

                    opacity: 1;

                    .nav-icon + .nav-text {
                        display: inline-block;
                    }

                    @include vertical-nav.vertical-nav-link-colors();

                    &.active > .nav-text {
                        color: inherit;
                    }
                }
            }
        }

        .clr-vertical-nav .nav-trigger {
            display: none;
        }
    }

    &.open-hamburger-menu {
        .header {
            .branding {
                position: fixed;
                top: 0;
                left: 0;
                overflow: hidden;
                width: responsive-nav-variables.$clr-sliding-panel-width;
                max-width: responsive-nav-variables.$clr-sliding-panel-width;
                z-index: responsive-nav-variables.$clr-sliding-panel-z-index + 1;
                padding-left: tokens.$cds-global-space-9;

                & > .nav-link {
                    overflow: hidden;
                }
                //deprecate .clr-icon, .logo
                .clr-icon,
                .logo,
                cds-icon,
                clr-icon {
                    display: inline-block;
                }

                cds-icon[shape='vm-bug'],
                clr-icon[shape='vm-bug'],
                .clr-vmw-logo {
                    background-color: header-variables.$clr-header-bg-color;
                    border-radius: tokens.$cds-alias-object-border-radius-100;
                }

                .title {
                    color: vertical-nav-variables.$clr-vertical-nav-item-color;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }

        .clr-nav-close {
            @include res.menu-trigger();
            @include res.hamburger-menu-trigger-animation();
        }

        .header-nav.clr-nav-level-1,
        .subnav.clr-nav-level-1,
        .sub-nav.clr-nav-level-1,
        .clr-vertical-nav.clr-nav-level-1 {
            padding-top: calc(header-variables.$clr-header-height + tokens.$cds-global-space-9);
            transform: translateX(0);
            transition: responsive-nav-variables.$clr-sliding-panel-animation;
        }
    }

    &.open-overflow-menu {
        .header-nav.clr-nav-level-2,
        .subnav.clr-nav-level-2,
        .sub-nav.clr-nav-level-2,
        .clr-vertical-nav.clr-nav-level-2 {
            transform: translateX(0);
            transition: responsive-nav-variables.$clr-sliding-panel-animation;
        }

        .header-nav.clr-nav-level-2,
        .subnav.clr-nav-level-2,
        .sub-nav.clr-nav-level-2 {
            padding-top: tokens.$cds-global-space-9;
        }

        .clr-nav-close {
            @include res.menu-trigger(0, auto, (calc(-1 * #{responsive-nav-variables.$clr-trigger-position})));
            @include res.overflow-menu-trigger-animation();
        }
    }

    &.open-hamburger-menu {
        .header-nav.clr-nav-level-1,
        .subnav.clr-nav-level-1,
        .sub-nav.clr-nav-level-1,
        .clr-vertical-nav.clr-nav-level-1 {
            overflow: inherit;
            width: responsive-nav-variables.$clr-sliding-panel-width;
            max-width: responsive-nav-variables.$clr-sliding-panel-width;

            &::before {
                content: none;
            }

            & .nav-link {
                width: 100%;
            }
        }
    }

    &.open-overflow-menu {
        .header-nav.clr-nav-level-2,
        .subnav.clr-nav-level-2,
        .sub-nav.clr-nav-level-2,
        .clr-vertical-nav.clr-nav-level-2 {
            width: responsive-nav-variables.$clr-sliding-panel-width;
            max-width: responsive-nav-variables.$clr-sliding-panel-width;
        }
    }
}
