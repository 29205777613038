.toast-container {
    .fade {
        opacity: initial;
    }

    .toast {
        --bs-toast-zindex: 1090;
        --bs-toast-padding-x: 0.6rem;
        --bs-toast-padding-y: 0.4rem;
        --bs-toast-spacing: 0.6rem;
        --bs-toast-max-width: 350px;
        --bs-toast-font-size: 0.7rem;
        --bs-toast-border-radius: 0.3rem;
        --bs-toast-border-width: var(--bs-border-width);
        $bs-background-color: #f8f9fa;
        --bs-toast-bg:  #{rgba($bs-background-color, .90)};
        --bs-toast-header-bg:  #{rgba($bs-background-color, .90)};

        width: var(--bs-toast-max-width);
        max-width: 100%;
        font-size: var(--bs-toast-font-size);
        pointer-events: auto;
        background-clip: padding-box;
        border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
        border-radius: var(--bs-toast-border-radius);

        .btn {
            border: none;
            margin: 0;
            padding: 0;
            margin-left: var(--cds-global-space-2);

            cds-icon {
                color: var(--cds-alias-typography-color-200);
            }

            &:focus {
                box-shadow: none;
            }

            &:hover {
                background-color: inherit;
            }
        }

        .toast-header {
            strong {
                margin-top: var(--cds-global-space-3);
            }

            small {
                margin-top: var(--cds-global-space-3);
            }
        }

        .toast-simple-body {
            display: flex;
            padding: var(--bs-toast-padding-x);

            .toast-simple-text {
                width: 100%;
                word-break: break-word;
                align-self: center;
            }

            .btn {
                cds-icon {
                    align-self: start;
                }
            }

            cds-icon {
                align-self: center;
            }
        }

        @keyframes progressonbarto0 {
            0% {
                width: 100%;
            }

            100% {
                width: 0%;
            }
        }

        .progress {
            border-bottom-left-radius: var(--bs-toast-border-radius);
            border-bottom-right-radius: var(--bs-toast-border-radius);

            .progress-bar {
                animation-name: progressonbarto0;
                animation-timing-function: linear;
                animation-iteration-count: 1;
                height: 100%;
            }
        }
    }
}
