.statusbar-header {
    display: flex;
    padding: 0.4rem;

    .is-warning {
        animation: statusbar-header-warning 3s infinite;
    }

    .is-warning .statusbar-trigger-icon {
        fill: inherit;
    }
}

.statusbar-header-content {
    display: flex;
    flex: 1 1 auto;
}

@keyframes statusbar-header-warning {
    0%, 49% {
        background-color: red;
        color: white;
        fill: white;
    }

    50%, 100% {
        background-color: var(--cds-alias-object-container-background-tint);
        color: var(--cds-global-color-construction-700);
        fill: var(--cds-global-color-construction-700);
    }
}

.statusbar-trigger {
    border: 0;
    background: transparent;
    align-items: center;
    display: flex;
    border-left-width: var(--clr-global-borderwidth);
    border-left-style: solid;
    border-left-color: var(--cds-alias-object-border-color);

    .statusbar-trigger-icon {
        fill: var(--cds-alias-object-interaction-color)
    }
}

.statusbar {
    .statusbar-content {
        display: none;
        padding: 0 0.3rem 0.6rem;
        //max-height: 370px;
        overflow-y: auto;
        margin-bottom: 0.3rem;
    }

    &.is-open {
        .statusbar-header {
            padding-bottom: 0.2rem;
            border-bottom-width: var(--clr-global-borderwidth);
            border-bottom-style: solid;
            border-bottom-color: var(--cds-alias-object-border-color);
        }

        .statusbar-content {
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
        }
    }
}

.statusbar-content .datagrid-wrapper[mblazor],
.content-area .datagrid-wrapper[mblazor],
.modal-body .datagrid-wrapper[mblazor] {
    flex-shrink: 1;
    flex-basis: auto;
    display: flex;
    flex-direction: column;
    //min-height: 30vh;
}

@media screen and (max-height: 600px) {
    .statusbar-content .datagrid-wrapper[mblazor],
    .content-area .datagrid-wrapper[mblazor],
    .modal-body .datagrid-wrapper[mblazor] {
        min-height: 80vh;
    }
}

.statusbar-content table[mblazor].fixed-header thead > tr.header-primary > th,
.statusbar-content table[mblazor].fixed-all thead > tr.header-primary > th,
.modal-body table[mblazor].fixed-header thead > tr.header-primary > th,
.modal-body table[mblazor].fixed-all thead > tr.header-primary > th {
    top: 0;
}

.statusbar-content table[mblazor].datagrid-footer,
.statusbar-content table[mblazor].datagrid-footer,
.modal-body table[mblazor].datagrid-footer,
.modal-body table[mblazor].datagrid-footer {
    bottom: 0;
}
