﻿/**Buttons*/
button[mblazor].btn-state-host {
    position: relative;

    .btn-content {
        opacity: 0;
    }
}

button[mblazor] .spinner[name=state-indicator] {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
}

button[mblazor] .spinner-check[name=state-indicator] {
    animation: grow 0.3s ease-in-out;
}
