.grid-wrapper {
    display: flex;
    flex-direction: column;

    .datagrid-wrapper {
        .datagrid-footer {
            position: sticky;
            bottom:-1.2rem;
        }
    }
}

.grid-wrapper-responsive {
    flex-shrink: 1 !important;
    max-height: 70vh;
    min-height: 0;

    &:not(:has(~ .grid-wrapper-responsive)) {
        max-height: 100vh;
    }

    .statusbar-content .datagrid-wrapper[mblazor],
    .content-area .datagrid-wrapper[mblazor],
    .modal-body .datagrid-wrapper[mblazor] {
        overflow: inherit;
        overflow-y: auto;
    }



    .datagrid-wrapper {
        .datagrid {
            overflow: auto;
            
            table > thead > tr.header-primary > th {
                top: 0;
            }
        }

        .datagrid-footer {
            position: relative;
            bottom: 0;
        }
    }
}
